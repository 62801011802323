
import GoogleSignIn from "@/shared/components/GoogleSignIn.vue";
import BrandIcon from "@/shared/components/Icons/BrandIcon.vue";
import PageLayout from "@/shared/components/Layouts/PageLayout.vue";
import { useAuthentication } from "@/shared/composables/useAuthentication";
import routeNames from "@/web/router/routeNames";
import LoginBackground from "@/web/views/Login/LoginBackground.vue";
import LoginViaEmailForm from "@/web/views/Login/LoginViaEmailForm.vue";
import TextDivider from "@/shared/components/TextDivider.vue";
import LayoutIcon, {
  LayoutIconName,
} from "@/shared/components/Icons/LayoutIcon.vue";
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import { config } from "@/shared/utils/config";
import { openInNewTab } from "@/shared/utils/browser";
import LoginFeaturedArticle, {
  FeaturedArticle,
} from "@/web/views/Login/LoginFeaturedArticle.vue";

/** Only to be used in Login page */
type VideoSteps = {
  title: string;
  description?: string;
  iconName: LayoutIconName;
};

export default defineComponent({
  setup() {
    const { t } = useI18n();
    const route = useRoute();
    const router = useRouter();

    const { handleLogin, loginLoading } = useAuthentication();

    const authenticateUserViaGoogle = async () => {
      try {
        await handleLogin();

        // Handle Redirect
        if (route.query.redirectTo) {
          router.push(String(route.query.redirectTo ?? ""));
        } else {
          router.push({ name: routeNames.home });
        }
      } catch (e) {
        console.log("GoogleSignIn error", e);
      }
    };

    const authenticateUserViaEmail = async (formState: any) => {
      try {
        await handleLogin({
          method: "email-password",
          authenticateViaEmailInput: {
            email: formState.email,
            password: formState.password,
            isRegistration: false,
          },
        });

        // Handle Redirect
        if (route.query.redirectTo) {
          router.push({ path: String(route.query.redirectTo) });
        } else {
          router.push({ name: routeNames.home });
        }
      } catch (e) {
        console.log("GoogleSignIn error", e);
      }
    };

    const aboutPerformerCreatorsItems = [
      t("Quality Guaranteed"),
      t("Zero hassle"),
      t("SMB-friendly price"),
    ];

    const recentWorks: LayoutIconName[] = [
      "recentWork01",
      "recentWork02",
      "recentWork03",
      "recentWork04",
    ];

    const steps: VideoSteps[] = [
      {
        title: t("Order"),
        description: t("Tell us the kind of videos you want us to make."),
        iconName: "stepOrder",
      },
      {
        title: t("Choose"),
        description: t("Choose the model creator who will create your videos."),
        iconName: "stepChoose",
      },
      {
        title: t("Ship"),
        description: t("Ship your product to your chosen model creator."),
        iconName: "stepShip",
      },
    ];

    const featuredArticles: FeaturedArticle[] = [
      // PR Times
      {
        title: t("www.prtimes.jp"),
        description: t("Kamilas4am raised $170,000 for pre-seed fundraising"),
        iconName: "articlePrTimes",
      },
      // When in Manila
      {
        title: t("www.wheninmanila.com"),
        description: t(
          "Real life Dal-mi and Do-san (Startup couple) launching their beta product"
        ),
        iconName: "articleWhenInManila",
      },
    ];

    return {
      t,
      config,
      routeNames,
      authenticateUserViaGoogle,
      authenticateUserViaEmail,
      loginLoading,
      aboutPerformerCreatorsItems,
      recentWorks,
      steps,
      featuredArticles,
      handleLearnMore: () => {
        openInNewTab(config.merchantInfoHomeUrl);
      },
      redirectToRegister: () => {
        router.push({ name: routeNames.register });
      },
      openInNewTab,
    };
  },
  components: {
    PageLayout,
    BrandIcon,
    GoogleSignIn,
    LoginBackground,
    LayoutIcon,
    LoginFeaturedArticle,
    LoginViaEmailForm,
    TextDivider,
  },
});
