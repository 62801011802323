import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "featured-article text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LayoutIcon = _resolveComponent("LayoutIcon")!
  const _component_a_typography_text = _resolveComponent("a-typography-text")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_LayoutIcon, {
      name: _ctx.article.iconName,
      class: "featured-article__image mb-7"
    }, null, 8, ["name"]),
    _createVNode(_component_a_typography_text, { class: "featured-article__title block mb-4" }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.article.title), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_a_typography_text, { class: "featured-article__description block italic" }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.article.description), 1)
      ]),
      _: 1
    })
  ]))
}