import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_InputPassword = _resolveComponent("InputPassword")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_FormGroup = _resolveComponent("FormGroup")!

  return (_openBlock(), _createBlock(_component_FormGroup, {
    model: _ctx.formState,
    loading: _ctx.loading,
    onFinish: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('on-finish', _ctx.formState))),
    class: "login-via-email-form w-full"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_InputText, {
        name: "email",
        value: _ctx.formState.email,
        "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formState.email) = $event)),
        placeholder: _ctx.t('username@domain.com'),
        rules: [
        _ctx.makeRequiredRule(_ctx.t('This field is required.')),
        _ctx.makeEmailRule(_ctx.t('Please enter a valid email address.')),
      ]
      }, null, 8, ["value", "placeholder", "rules"]),
      _createVNode(_component_InputPassword, {
        name: "password",
        value: _ctx.formState.password,
        "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formState.password) = $event)),
        placeholder: _ctx.t('Your password'),
        rules: [_ctx.makeRequiredRule(_ctx.t('This field is required.'))]
      }, null, 8, ["value", "placeholder", "rules"]),
      _createVNode(_component_a_button, {
        type: "primary",
        "html-type": "submit",
        loading: _ctx.loading,
        class: "mt-4",
        block: ""
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.t("Login")), 1)
        ]),
        _: 1
      }, 8, ["loading"])
    ]),
    _: 1
  }, 8, ["model", "loading"]))
}