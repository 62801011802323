
import FormGroup from "@/shared/components/Forms/FormGroup.vue";
import InputPassword from "@/shared/components/Forms/InputPassword.vue";
import InputText from "@/shared/components/Forms/InputText.vue";
import {
  makeEmailRule,
  makeRequiredRule,
} from "@/shared/utils/validators/commonValidators";
import { defineComponent, reactive } from "vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  props: {
    loading: Boolean,
  },
  setup() {
    const { t } = useI18n();

    const formState = reactive({
      email: "",
      password: "",
    });

    return {
      t,
      formState,
      makeRequiredRule,
      makeEmailRule,
    };
  },
  emits: ["on-finish"],
  components: {
    InputText,
    InputPassword,
    FormGroup,
  },
});
