
import LayoutIcon, {
  LayoutIconName,
} from "@/shared/components/Icons/LayoutIcon.vue";
import { defineComponent, PropType } from "vue";

export type FeaturedArticle = {
  title: string;
  description: string;
  iconName: LayoutIconName;
};

export default defineComponent({
  props: {
    article: {
      type: Object as PropType<FeaturedArticle>,
      required: true,
    },
  },
  components: { LayoutIcon },
});
