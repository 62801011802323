import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "login-page-background complex-bg" }
const _hoisted_2 = { class: "complex-bg__icons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LayoutIcon = _resolveComponent("LayoutIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_LayoutIcon, {
        name: "brushBlue",
        id: "brushBlue"
      }),
      _createVNode(_component_LayoutIcon, {
        name: "brushBlue",
        id: "brushBlue2"
      }),
      _createVNode(_component_LayoutIcon, {
        name: "brushPink",
        id: "brushPink"
      }),
      _createVNode(_component_LayoutIcon, {
        name: "brushPink",
        id: "brushPink2"
      }),
      _createVNode(_component_LayoutIcon, {
        name: "brushPink",
        id: "brushPink3"
      }),
      _createVNode(_component_LayoutIcon, {
        name: "lineOrange01",
        id: "lineOrange01"
      }),
      _createVNode(_component_LayoutIcon, {
        name: "lineRed01",
        id: "lineRed01"
      }),
      _createVNode(_component_LayoutIcon, {
        name: "lineViolet01",
        id: "lineViolet01"
      }),
      _createVNode(_component_LayoutIcon, {
        name: "lineViolet02",
        id: "lineViolet02"
      }),
      _createVNode(_component_LayoutIcon, {
        name: "dotsYellow",
        id: "dotsYellow-left"
      }),
      _createVNode(_component_LayoutIcon, {
        name: "tiktok",
        id: "tiktok"
      }),
      _createVNode(_component_LayoutIcon, {
        name: "twinkle2",
        id: "twinkle2"
      }),
      _createVNode(_component_LayoutIcon, {
        name: "legal",
        id: "legal"
      }),
      _createVNode(_component_LayoutIcon, {
        name: "followers",
        id: "followers"
      }),
      _createVNode(_component_LayoutIcon, {
        name: "stars5Yellow",
        id: "stars5Yellow"
      }),
      _createVNode(_component_LayoutIcon, {
        name: "playButton",
        id: "playButton"
      }),
      _createVNode(_component_LayoutIcon, {
        name: "dotsYellow",
        id: "dotsYellow-right"
      }),
      _createVNode(_component_LayoutIcon, {
        name: "subscribe",
        id: "subscribe"
      }),
      _createVNode(_component_LayoutIcon, {
        name: "ideas",
        id: "ideas"
      }),
      _createVNode(_component_LayoutIcon, {
        name: "twinkle3",
        id: "twinkle3"
      })
    ])
  ]))
}